import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'
import * as Sentry from '@sentry/react'

import $app from './app'
import $business from './business'
import $customer from './customer'
import $category from './category'
import $service from './service'
import $resource from './resource'
import $day from './day'
import $order from './order'
import $wlist from './wlist'

const store = configureStore({
  reducer: {
    $app: $app.reducer,
    $business: $business.reducer,
    $customer: $customer.reducer,
    $category: $category.reducer,
    $service: $service.reducer,
    $resource: $resource.reducer,
    $day: $day.reducer,
    $order: $order.reducer,
    $wlist: $wlist.reducer,
  },
})

export const businessCode = () => {
  return store.getState().$business.code
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export default store



export const API_URL = process.env.REACT_APP_API_URL

export const request = async (uri: string, method: string = 'GET', data: object | null = null) => {
  const { signature, timestamp } = generateHMACSignature(data)

  const options: RequestInit = {
    method: method.toUpperCase(),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Request-From': 'booking-server',
      'X-Timestamp': timestamp,
      'X-Signature': signature,
    },
    body: null,
  }

  let query = ''
  if (data) {
    if (options.method === 'GET') {
      query = queryBuilder(data)
    } else {
      options.body = JSON.stringify(data)
    }
  }

  try {
    const response = await fetch(`${API_URL}${uri}${query}`, options)
    const result = await response.json()
    if (response.ok) {
      return resultHandler(result)
    } else {
      throw new Error('API Error')
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        uri: `${API_URL}${uri}${query}`,
        method: options.method,
        data,
      },
    })

    throw error
  }
}

export type ApiResponse = {
  status: string
  [key: string]: any
}

const resultHandler = (result: ApiResponse) => {
  // const version: string = store.getState().$app.version
  // if (version != null && version != result.version) {
  //   window.location.reload()
  // }
  // store.dispatch($app.set.version(result.version))

  // messageHandler(result.messages)

  return result
}

const queryBuilder = (data: any) => {
  const params = new URLSearchParams()

  for (const key in data) {
    if (data[key] != null) {
      if (Array.isArray(data[key])) {
        for (const value of data[key]) {
          params.append(key, value)
        }
      } else {
        params.append(key, data[key])
      }
    }
  }
  
  const queryString = params.toString()
  return queryString ? `?${queryString}` : ''
}

const generateHMACSignature = (data: object | null) => {
  const secretKey = process.env.REACT_APP_API_KEY ?? ''
  const payload = 'tiffulit' //JSON.stringify(data)
  const timestamp = Date.now().toString()

  const message = `${timestamp}:${payload}`
  const signature = CryptoJS.HmacSHA256(message, secretKey).toString()

  return { signature, timestamp }
}